import { createSlice } from "@reduxjs/toolkit";
import initialState, { Validation, VehicleProfile } from "./initialState";

const Vehicle = createSlice({
  name: "Vehicle",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      const { type, name, value } = action.payload;
      if (type === "detail") {
        state.Detail = { ...state.Detail, [name]: value };
      } else if (type === "validation") {
        state.Validation = {
          ...state.Validation,
          [name]: { ...state.Validation[name], ...value },
        };
      }
    },
    setList(state, action) {
      state.List = action.payload;
    },
    setModalData(state, action) {
      state.Detail = action.payload;
    },
    setClearData(state) {
      state.Detail = VehicleProfile;
      state.Validation = Validation;
      state.Updated = false;
    },
    clear(state) {
      state.List = [];
      state.Update = false;
      state.Detail = VehicleProfile;
      state.Validation = Validation;
    },
    setUpdate(state, action) {
      state.Updated = action.payload;
    },
  },
});

export const {
  clear,
  setList,
  setClearData,
  setData,
  setModalData,
  setUpdate,
} = Vehicle.actions;

export default Vehicle.reducer;
