import { createSlice } from "@reduxjs/toolkit";

const permission = createSlice({
  name: "Permission",
  initialState: {
    List: [],
    ModuleList: [],
    ReportList: [],
    CMP: [],
  },
  reducers: {
    setList(state, action) {
      state.List = action.payload;
    },
    setModuleList(state, action) {
      state.ModuleList = action.payload;
    },
    setReportList(state, action) {
      state.ReportList = action.payload;
    },
    setData(state, action) {
      const { index, column, value } = action.payload;
      state.List[index][column] = value;
    },
    setModuleData(state, action) {
      const { index, column, value } = action.payload;
      state.ModuleList[index][column] = value;
    },
    setReportData(state, action) {
      const { index, column, value } = action.payload;
      state.ReportList[index][column] = value;
    },
    clearData(state) {
      state.List = [];
      state.ModuleList = [];
      state.ReportList = [];
    },
    setModulePermission(state, action) {
      state.CMP = action.payload;
    },
  },
});

export const {
  setList,
  setModuleList,
  setReportList,
  setData,
  setModuleData,
  setReportData,
  clearData,
  setModulePermission,
} = permission.actions;
export default permission.reducer;
