import { createSlice } from "@reduxjs/toolkit";
import initialState, { DriverDetail, Validation } from "./initialState";

const VM = createSlice({
  name: "Driver",
  initialState: initialState,
  reducers: {
    setUpdate(state, action) {
      state.Update = action.payload;
    },
    setData(state, action) {
      const { type, name, value } = action.payload;
      if (type === "detail") {
        state.Detail = { ...state.Detail, [name]: value };
      } else if (type === "validation") {
        state.Validation = {
          ...state.Validation,
          [name]: { ...state.Validation[name], ...value },
        };
      }
    },
    setClearData(state) {
      state.Detail = DriverDetail;
      state.Validation = Validation;
      state.Update = false;
    },
    setList(state, action) {
      state.List = action.payload;
    },
    setModalData(state, action) {
      state.Detail = action.payload;
    },
    clear(state) {
      state.Detail = DriverDetail;
      state.Validation = Validation;
      state.Update = false;
      state.List = [];
    },
  },
});

export const {
  setData,
  setClearData,
  setList,
  setModalData,
  clear,
  setUpdate,
} = VM.actions;
export default VM.reducer;
