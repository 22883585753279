const Index = {
  IsMobileView: false,
  IsCollapsed: false,
  IsMinimalView: false,
  IsPartialCollapsed: false,
  SidebarWidth: "320px",
  IsProfileOpened: false,
  IsGroupInfoOpened: false,
};
export default Index;
