import { createSlice } from "@reduxjs/toolkit";
const MVB = createSlice({
  name: "MVB",
  initialState: {
    OnTripCount: null,
    TodayCount: null,
    TomorrowCount: null,
    DraftCount: null,
    OtherCount: null,
    list: [],
  },
  reducers: {
    setList(state, action) {
      if (action.payload !== null) {
        const {
          TomorrowCount,
          TodayCount,
          OtherCount,
          OnTripCount,
          DraftCount,
          list,
        } = action.payload;

        if (OtherCount !== undefined && OtherCount !== null)
          state.OtherCount = OtherCount;

        if (TodayCount !== undefined && TodayCount !== null)
          state.TodayCount = TodayCount;

        if (TomorrowCount !== undefined && TomorrowCount !== null)
          state.TomorrowCount = TomorrowCount;

        if (OnTripCount !== undefined && OnTripCount !== null)
          state.OnTripCount = OnTripCount;

        if (DraftCount !== undefined && DraftCount !== null)
          state.DraftCount = DraftCount;

        if (list !== undefined && list !== null) state.list = list;
      } else {
        state.OnTripCount = null;
        state.OtherCount = null;
        state.TodayCount = null;
        state.TomorrowCount = null;
        state.DraftCount = null;
        state.OnTripCount = null;
        state.list = [];
      }
    },
  },
});

export const { setList } = MVB.actions;
export default MVB.reducer;
