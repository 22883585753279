import { createSlice } from "@reduxjs/toolkit";
import initialState, {
  SubscriptionItem,
  SubscriptionValidation,
} from "./initialState";

const VM = createSlice({
  name: "SM",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      const { type, name, value } = action.payload;
      if (type === "detail") {
        state.Detail = { ...state.Detail, [name]: value };
      } else if (type === "validation") {
        state.Validation = {
          ...state.Validation,
          [name]: { ...state.Validation[name], ...value },
        };
      }
    },
    setClearData(state) {
      state.Detail = { ...state.Detail, ...SubscriptionItem };
      state.Validation = { ...state.Validation, ...SubscriptionValidation };
      // state.List = [];
    },
    setList(state, action) {
      const { list } = action.payload;
      state.List = list;
    },
    setModalData(state, action) {
      state.Validation = { ...state.Validation, ...SubscriptionValidation };
      state.Detail = action.payload;
    },
  },
});

export const { setData, setClearData, setList, setModalData } = VM.actions;
export default VM.reducer;
