export const DriverDetail = {
  DriverID: null,
  RegID: "",
  DriverName: "",
  DriverPhoto: null,
  NRIC: "",
  NRICFileName: null,
  DOB: "",
  Gender: "",
  PhoneNo: "",
  Email: "",
  DrivingLicenseNo: "",
  DrivingLicenseExpiry: "",
  LicenseFileName: null,
  PSVNo: "",
  PSVExpiry: "",
  PSVFileName: null,
  sm_id: null,
  BankName: null,
  BankAccountNo: "",
  Status: "",
  AdminNotes: "",
  isApproved: false,
};
export const Validation = {
  DriverID: null,
  RegID: { label: "Telegram Reference", require: true, valid: true },
  DriverName: { label: "Driver Name", require: true, valid: true },
  DriverPhoto: { label: "Profile Photo", require: true, valid: true },
  DOB: {
    label: "Birthday",
    require: true,
    ValidateAge: true,
    minAge: 20,
    maxAge: 65,
    valid: true,
  },
  Gender: { label: "Gender", require: true, valid: true },
  NRIC: {
    label: "Driver NRIC",
    require: true,
    ValidateNRIC: true,
    maxLength: 12,
    valid: true,
  },
  NRICFileName: { label: "Driver NRIC Front", require: true, valid: true },
  PhoneNo: { label: "Mobile No", require: true, valid: true },
  Email: { label: "Email", require: true, ValidateEmail: true, valid: true },
  DrivingLicenseNo: { label: "License No", require: false, valid: true },
  DrivingLicenseExpiry: { label: "License Expiry", require: true, valid: true },
  LicenseFileName: {
    label: "Driving License Front",
    require: true,
    valid: true,
  },
  PSVNo: { label: "PSV No", require: false, valid: true },
  PSVExpiry: { label: "PSV Expiry", require: false, valid: true },
  PSVFileName: { label: "Driver PSV Front", require: false, valid: true },
  sm_id: { label: "Subscription", require: true, valid: true },
  BankName: { label: "Bank Name", require: false, valid: true },
  BankAccountNo: { label: "Bank Account No", require: false, valid: true },
  Status: { label: "Status", require: true, valid: true },
  AdminNotes: { label: "Admin Notes", require: false, valid: true },
  isApproved: { label: "Approval", require: false, valid: true },
};
const Index = {
  Update: false,
  List: [],
  Detail: DriverDetail,
  Validation: Validation,
};
export default Index;
