import axios from "../../api/axios";
import { setError } from "../auth/authThunk";
import { setMenuList } from "../menu";
const getMenuList = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/menu/list", data).catch((e) => {
        throw e;
      });
      if (response.statusText === "OK" || response.status === 200) {
        const {
          data: { type, message },
        } = response;
        if (type === "success") {
          const { data: menu } = response.data;
          dispatch(setMenuList([...menu]));
        } else if (type === "error") {
          throw new Error(message);
        }
      }
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export { getMenuList };
