export const BookingDetail = {
  BookingID: null,
  BookingRefID: null,
  LeaderRefNo: null,
  PassengerID: null,
  PassengerNo: null,
  PassengerName: null,
  PassengerEmail: null,
  PickupDate: null,
  PickupTime: null,
  mc_id: null, //Vehicle Category  (or) Vehicle Type
  Assign: null, //Direct = 1 , Bidding = 2
  lgm_id: null,
  DriverID: null,
  VehicleInfo: null,
  Seats: null,
  Luggage: null,
  CollectFare: null,
  ActualDriverFare: null,
  LeaderCommision: null,
  PickupLocation: [
    { vbl_id: null, triptype: 1, location: null, geoLocation: null },
  ],
  DropOffLocation: [
    { vbl_id: null, triptype: 2, location: null, geoLocation: null },
  ],
  Notes: null,
  LeaderNotes: null,
  ScheduledDate: null,
  StartedDate: null,
  OnTripDate: null,
  CompletedDate: null,
  Status: 1,
};
export const Validation = {
  BookingID: null,
  BookingRefID: { label: "Booking ID", require: false, valid: true },
  LeaderRefNo: { label: "Leader Reference No", require: false, valid: true },
  PassengerID: {
    label: "Passenger Phone No",
    require: true,
    valid: true,
  },
  PassengerNo: {
    label: "Passenger Phone No",
    require: true,
    valid: true,
  },
  PassengerFormattedNo: {
    label: "Passenger Phone No",
    require: false,
    valid: true,
  },
  PassengerName: {
    label: "Passenger Name",
    require: true,
    valid: true,
  },
  // PassengerEmail: {
  //   label: "Email",
  //   require: false,
  //   ValidateEmail: true,
  //   valid: true,
  // },
  PickupDate: {
    label: "Pickup Date",
    ValidateDate: true,
    require: true,
    valid: true,
  },
  PickupTime: {
    label: "Pickup Time (24h)",
    // ValidateDate: true,
    require: true,
    valid: true,
  },
  mc_id: { label: "Vehicle Type", require: true, valid: true },
  Assign: {
    label: "Assign",
    require: true,
    valid: true,
  },
  lgm_id: { label: "Group", require: false, valid: true },
  DriverID: { label: "Driver Info", require: true, valid: true },
  VehicleInfo: { label: "Vehicle Info", require: false, valid: true },
  Seats: {
    label: "Passengers",
    require: true,
    valid: true,
  },
  Luggage: {
    label: "Luggage",
    require: false,
    valid: true,
  },
  CollectFare: {
    label: "Collect Fare",
    AcceptOnlyNumbers: true,
    AllowNegative: false,
    require: true,
    valid: true,
  },
  ActualDriverFare: {
    label: "Actual Driver Fare",
    AcceptOnlyNumbers: true,
    AllowNegative: false,
    require: false,
    valid: true,
  },
  LeaderCommision: {
    label: "Leader Commision",
    AcceptOnlyNumbers: true,
    AllowNegative: false,
    require: false,
    valid: true,
  },
  PickupLocation: {
    label: "Pickup Location",
    require: true,
    valid: true,
    ValidateList: true,
    minLength: 1,
  },
  DropOffLocation: {
    label: "Drop-off  Location",
    require: true,
    valid: true,
    ValidateList: true,
    minLength: 1,
  },
  Notes: { label: "Notes", require: false, valid: true },
  LeaderNotes: { label: "Leader Notes", require: false, valid: true },
  ScheduledDate: { label: "Scheduled", require: false, valid: true },
  StartedDate: { label: "Started", require: false, valid: true },
  OnTripDate: { label: "On-Trip", require: false, valid: true },
  CompletedDate: { label: "Completed", require: false, valid: true },
  Status: { label: "Status", require: true, valid: true },
};

export const DriverBookingValidation = {
  BookingID: null,
  BookingRefID: { label: "Booking ID", require: false, valid: true },
  LeaderRefNo: { label: "Leader Reference No", require: false, valid: true },
  PassengerID: {
    label: "Passenger Phone No",
    require: true,
    valid: true,
  },
  PassengerNo: {
    label: "Passenger Phone No",
    require: true,
    valid: true,
  },
  PassengerFormattedNo: {
    label: "Passenger Phone No",
    require: false,
    valid: true,
  },
  PassengerName: {
    label: "Passenger Name",
    require: true,
    valid: true,
  },
  PickupDate: {
    label: "Pickup Date",
    ValidateDate: true,
    require: true,
    valid: true,
  },
  PickupTime: {
    label: "Pickup Time (24h)",
    // ValidateDate: true,
    require: true,
    valid: true,
  },
  mc_id: { label: "Vehicle Type", require: true, valid: true },
  Assign: {
    label: "Assign",
    require: true,
    valid: true,
  },
  lgm_id: { label: "Group", require: false, valid: true },
  DriverID: { label: "Driver Info", require: true, valid: true },
  VehicleInfo: { label: "Vehicle Info", require: false, valid: true },
  Seats: {
    label: "Passengers",
    require: false,
    valid: true,
  },
  Luggage: {
    label: "Luggage",
    require: false,
    valid: true,
  },
  CollectFare: {
    label: "Collect Fare",
    AcceptOnlyNumbers: true,
    AllowNegative: false,
    require: true,
    valid: true,
  },
  ActualDriverFare: {
    label: "Actual Driver Fare",
    AcceptOnlyNumbers: true,
    AllowNegative: false,
    require: false,
    valid: true,
  },
  LeaderCommision: {
    label: "Leader Commision",
    AcceptOnlyNumbers: true,
    AllowNegative: false,
    require: false,
    valid: true,
  },
  PickupLocation: {
    label: "Pickup Location",
    require: true,
    valid: true,
    ValidateList: true,
    minLength: 1,
  },
  DropOffLocation: {
    label: "Drop-off  Location",
    require: true,
    valid: true,
    ValidateList: true,
    minLength: 1,
  },
  Notes: { label: "Notes", require: false, valid: true },
  LeaderNotes: { label: "Leader Notes", require: false, valid: true },
  ScheduledDate: { label: "Scheduled", require: false, valid: true },
  StartedDate: { label: "Started", require: false, valid: true },
  OnTripDate: { label: "On-Trip", require: false, valid: true },
  CompletedDate: { label: "Completed", require: false, valid: true },
  Status: { label: "Status", require: true, valid: true },
};

export const QuickBookingValidation = {
  BookingRefID: { label: "Booking ID", require: false, valid: true },
  lgm_id: { label: "Group", require: false, valid: true },
  DriverID: { label: "Driver Info", require: true, valid: true },
  VehicleInfo: { label: "Vehicle Info", require: false, valid: true },
  CollectFare: {
    label: "Collect Fare",
    AcceptOnlyNumbers: true,
    AllowNegative: false,
    require: true,
    valid: true,
  },
  PickupLocation: {
    label: "Pickup Location",
    require: true,
    valid: true,
    ValidateList: true,
    minLength: 1,
  },
  DropOffLocation: {
    label: "Drop-off  Location",
    require: true,
    valid: true,
    ValidateList: true,
    minLength: 1,
  },
  Notes: { label: "Notes", require: false, valid: true },
  ScheduledDate: { label: "Scheduled", require: false, valid: true },
  StartedDate: { label: "Started", require: false, valid: true },
  OnTripDate: { label: "On-Trip", require: false, valid: true },
  CompletedDate: { label: "Completed", require: false, valid: true },
  Status: { label: "Status", require: true, valid: true },
};

export const FilterData = {
  FilterText: "",
  FilterOption: "0+",
};
const Index = {
  Update: false,
  CurrentStatus: 1,
  BookingID: null,
  ShowCancelModal: false,
  CancelData: null,
  ShowFareModel: false,
  List: [],
  Detail: BookingDetail,
  Validation: Validation,
  Filter: FilterData,
};
export default Index;
