const Index = {
  isMenuOpened: true,
  isHeaderShowed: false,
  MenuList: [],
  CirclePosition: { Top: 0, isBottom: false },
  ActiveMenu: null,
  //  {
  //   id: "",
  //   text: "",
  //   IconText: "",
  //   ShowAsIcon: true,
  //   ShowAsLabel: false,
  //   menuList: [],
  // },
  ActiveSubMenu: null,
};
export default Index;
