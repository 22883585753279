export const Item = {
  mc_id: null,
  mc_desc: "",
  mc_code: "",
  IsActive: true,
  IsVoid: false,
};
export const Validation = {
  mc_id: null,
  mc_desc: { label: "Description", require: true, valid: true },
  mc_code: { label: "Code", require: false, valid: true },
  IsActive: { label: "Status", require: true, valid: true },
  IsVoid: { label: "Void", require: false, valid: true },
};
const Index = {
  List: [],
  Detail: Item,
  Validation: Validation,
};

export default Index;
