import { Backdrop } from "@mui/material";

import Logo from "../../../assets/logo.png";
import styles from "./index.module.css";
const Index = () => {
  return (
    <Backdrop sx={{ zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.8)" }} open>
      <div className={styles["lds-circle"]}>
        {/* <div></div> */}
        <img src={Logo} alt="eevom" />
      </div>
    </Backdrop>
  );
};

export default Index;
