const Auth = {
  isAuthenticated: false,
  notification: null,
  UserLoginInfo: {},
  isLoading: 0,
  showModal: false,
  ActivateRegistration: false,
  // LgmList: [],
  // activeLGM: null,
};

export default Auth;
