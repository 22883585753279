import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
const Menu = createSlice({
  name: "Menu",
  initialState: initialState,
  reducers: {
    setMenuList(state, action) {
      state.MenuList = action.payload;
    },
    setActiveMenu(state, action) {
      state.ActiveMenu = action.payload;
    },
    setActiveSubMenu(state, action) {
      state.ActiveSubMenu = action.payload;
    },
    setMenuOpen(state, action) {
      state.isMenuOpened = action.payload;
    },
    setCirclePosition(state, action) {
      state.CirclePosition = { ...state.CirclePosition, ...action.payload };
    },
    setShowHeader(state, action) {
      state.isHeaderShowed = action.payload;
      state.isMenuOpened = !action.payload;
    },
    clearMenuState(state) {
      state.isMenuOpened = true;
      state.isHeaderShowed = false;
      state.MenuList = [];
      state.CirclePosition = { Top: 0, isBottom: false };
      state.ActiveMenu = null;
      state.ActiveSubMenu = null;
    },
  },
});

export const {
  setMenuList,
  setActiveMenu,
  setMenuOpen,
  setActiveSubMenu,
  setCirclePosition,
  setShowHeader,
  clearMenuState,
} = Menu.actions;
export default Menu.reducer;
