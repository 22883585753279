import { createSlice } from "@reduxjs/toolkit";
import initialState, { Item, Validation } from "./initialState";

const VM = createSlice({
  name: "VC",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      const { type, name, value } = action.payload;
      if (type === "detail") {
        state.Detail = { ...state.Detail, [name]: value };
      } else if (type === "validation") {
        state.Validation = {
          ...state.Validation,
          [name]: { ...state.Validation[name], ...value },
        };
      }
    },
    setClearData(state) {
      state.Detail = Item;
      state.Validation = Validation;
    },
    setModelData(state, action) {
      state.Detail = action.payload;
      state.Validation = Validation;
    },
    setList(state, action) {
      state.List = action.payload;
    },
    clear(state) {
      state.Detail = null;
      state.List = [];
      state.Validation = null;
    },
  },
});

export const {
  setData,
  setClearData,
  setModelData,
  setModelGroupList,
  setList,
  clear,
} = VM.actions;
export default VM.reducer;
