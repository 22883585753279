export const SubscriptionItem = {
  sm_id: null,
  sm_desc: "",
  sm_code: "",
  sm_cancel_charge: "",
  CancelChargeOn: 0,
  ReminderB4AutoComplete: 0,
  AutoCompleteCharges: 0,
  IsActive: true,
  IsVoid: false,
};
export const SubscriptionValidation = {
  sm_id: null,
  sm_desc: { label: "Description", require: true, valid: true },
  sm_code: { label: "Charges (RM)", require: true, valid: true },
  sm_cancel_charge: {
    label: "Cancel Charge (RM)",
    require: false,
    valid: true,
  },
  CancelChargeOn: {
    label: "Cancel Charge On",
    require: false,
    valid: true,
  },
  ReminderB4AutoComplete: {
    label: "Reminder before autocomplete",
    require: false,
    valid: true,
  },
  AutoCompleteCharges: {
    label: "Autocompletion Charges",
    require: false,
    valid: true,
  },
  IsActive: { label: "Status", require: true, valid: true },
  IsVoid: { label: "Void", require: false, valid: true },
};
const Index = {
  List: [],
  Detail: SubscriptionItem,
  Validation: SubscriptionValidation,
};
export default Index;
