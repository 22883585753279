const DriverProfile = {
  DriverID: null,
  Photo: null,
  DriverName: "",
  DoB: "",
  Gender: "",
  NRIC: "",
  NRICFront: null,
  MobileNo: "",
  Email: "",
  LicenseNo: "",
  LicenseExpiry: "",
  LicenseFront: null,
  PSVNo: "",
  PSVExpiry: "",
  PSVFront: null,
  BankName: "",
  BankAccountNo: "",
  Status: "",
};
const DriverProfileValidation = {
  DriverID: null,
  Photo: { label: "Profile Photo", require: true, valid: true },
  DriverName: { label: "Driver Name", require: true, valid: true },
  DoB: {
    label: "Birthday",
    require: true,
    ValidateAge: true,
    minAge: 20,
    maxAge: 65,
    valid: true,
  },
  Gender: { label: "Gender", require: true, valid: true },
  NRIC: {
    label: "Driver NRIC",
    require: true,
    ValidateNRIC: true,
    maxLength: 12,
    valid: true,
  },
  NRICFront: { label: "Driver NRIC Front", require: true, valid: true },
  MobileNo: { label: "Mobile No", require: false, valid: true },
  Email: { label: "Email", require: true, ValidateEmail: true, valid: true },
  LicenseNo: { label: "License No", require: false, valid: true },
  LicenseExpiry: { label: "License Expiry", require: true, valid: true },
  LicenseFront: {
    label: "Driving License Front",
    require: true,
    valid: true,
  },
  PSVNo: { label: "PSV No", require: false, valid: true },
  PSVExpiry: { label: "PSV Expiry", require: false, valid: true },
  PSVFront: { label: "Driver PSV Front", require: false, valid: true },
  BankName: { label: "Bank Name", require: true, valid: true },
  BankAccountNo: { label: "Bank Account No", require: true, valid: true },
  Status: { label: "Status", require: false, valid: true },
};

export const VehicleItem = {
  VehicleID: null,
  Photo: null,
  Model: "",
  MakeYear: "",
  Color: "",
  Category: "",
  PlatNo: "",
  Capacity: "",
  GrantPhoto: null,
  InsuranceNo: "",
  InsuranceExpiry: "",
  InsuranceCoverNote: "",
  RoadTaxNo: "",
  RoadTaxExpiry: "",
  RoadTaxPhoto: null,
  EVPNo: "",
  EVPExpiry: "",
  EVPPhoto: null,
  PuspakomCertPhoto: null,
  Status: "",
};

export const VehicleItemValidation = {
  VehicleID: null,
  Photo: { label: "Vehicle Photo", require: true, valid: true },
  Model: { label: "Car Model", require: true, valid: true },
  MakeYear: { label: "Car Year", require: true, valid: true },
  Color: { label: "Car Color", require: true, valid: true },
  Category: { label: "Car Category", require: false, valid: true },
  PlatNo: { label: "Plate No", require: true, valid: true },
  Capacity: { label: "Luggage Capacity", require: true, valid: true },
  GrantPhoto: { label: "Car Grant", require: true, valid: true },
  InsuranceNo: { label: "Insurance No", require: false, valid: true },
  InsuranceExpiry: {
    label: "Insurance Expiry",
    require: true,
    // ValidateDate: true,
    // minMonth: 6,
    valid: true,
  },
  InsuranceCoverNote: {
    label: "Insurance Cover Note",
    require: true,
    valid: true,
  },
  RoadTaxNo: { label: "Road Tax No", require: false, valid: true },
  RoadTaxExpiry: {
    label: "Road Tax Expiry",
    require: true,
    valid: true,
    // ValidateDate: true,
    // minMonth: 6,
  },
  RoadTaxPhoto: {
    label: "Road Tax Photo",
    require: true,
    valid: true,
  },

  EVPNo: { label: "EVP No", require: false, valid: true },
  EVPExpiry: { label: "EVP Expiry Date", require: false, valid: true },
  EVPPhoto: {
    label: "EVP Photo",
    require: false,
    valid: true,
  },
  PuspakomCertPhoto: {
    label: "Puspakom Cert Photo",
    require: false,
    valid: true,
  },
  Status: { label: "Status", require: false, valid: true },
};

const Index = {
  activeStep: 0,
  HideToolBar: false,
  ShowVehicleForm: false,
  Driver: {
    Profile: DriverProfile,
    Validation: DriverProfileValidation,
  },
  Vehicle: {
    List: [],
    ListValidation: {
      List: { label: "Vehicle Profile", require: true, minCount: 1 },
    },
    Item: VehicleItem,
    ItemValidation: VehicleItemValidation,
  },
};

export { DriverProfile, DriverProfileValidation };
export default Index;
