import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import { VehicleItem, VehicleItemValidation } from "./initialState";
const Menu = createSlice({
  name: "PubReg",
  initialState: initialState,
  reducers: {
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    setHideToolbar(state, action) {
      state.HideToolBar = action.payload;
    },
    setDriverFormField(state, action) {
      const { name, value } = action.payload;
      state.Driver.Profile = { ...state.Driver.Profile, [name]: value };
    },
    setAllProfile(state, action) {
      state.Driver.Profile = action.payload.DriverProfile;
      state.Vehicle.List = action.payload.VehicleProfile;
    },
    setShowVehicleForm(state, action) {
      state.ShowVehicleForm = action.payload;
      state.HideToolBar = action.payload;
    },
    setVehicleFormField(state, action) {
      const { name, value } = action.payload;
      state.Vehicle.Item = { ...state.Vehicle.Item, [name]: value };
    },
    resetVehicleFormFields(state) {
      state.Vehicle.Item = VehicleItem;
      state.Vehicle.ItemValidation = VehicleItemValidation;
    },
    setDriverValidation(state, action) {
      const { name, value } = action.payload;
      state.Driver.Validation = {
        ...state.Driver.Validation,
        [name]: { ...state.Driver.Validation[name], ...value },
      };
    },
    setVehicleValidation(state, action) {
      const { name, value, type } = action.payload;
      if ((type || "").toLowerCase() === "item") {
        state.Vehicle.ItemValidation = {
          ...state.Vehicle.ItemValidation,
          [name]: {
            ...state.Vehicle.ItemValidation[name],
            ...value,
          },
        };
      } else if ((type || "").toLowerCase() === "list")
        state.Vehicle.ListValidation = {
          ...state.Vehicle.ListValidation,
          [name]: {
            ...state.Vehicle.ListValidation[name],
            ...value,
          },
        };
    },
    addVehicleInList(state) {
      state.Vehicle.List = [...state.Vehicle.List, state.Vehicle.Item];
      state.Vehicle.Item = VehicleItem;
      state.Vehicle.ItemValidation = VehicleItemValidation;
    },
  },
});

export const {
  setDriverFormField,
  setDriverValidation,
  setActiveStep,
  setHideToolbar,
  setVehicleFormField,
  setVehicleValidation,
  setShowVehicleForm,
  resetVehicleFormFields,
  addVehicleInList,
  setAllProfile,
} = Menu.actions;
export default Menu.reducer;
