import { createSlice } from "@reduxjs/toolkit";

const HistoryLog = createSlice({
  name: "HL",
  initialState: {
    List: [],
    Validation: [],
  },
  reducers: {
    setList(state, action) {
      state.List = action.payload;
    },
    setValidationData(state, action) {
      state.Validation = action.payload;
    },
    clear(state) {
      state.List = [];
      state.Validation = [];
    },
  },
});

export const { setRefID, setList, setValidationData, clear } =
  HistoryLog.actions;
export default HistoryLog.reducer;
