import { createSlice } from "@reduxjs/toolkit";
import initialState, {
  GroupDetail,
  GroupDetailValidation,
} from "./initialState";

const group = createSlice({
  name: "Group",
  initialState: initialState,
  reducers: {
    clear(state) {
      state.DropDownList = [{ lgm_id: "", lgm_desc: "" }];
      state.GroupDetail = GroupDetail;
      state.Validation = GroupDetailValidation;
      state.GroupID = null;
      state.GroupList = [];
      state.DriverList = [];
    },
    clearData(state) {
      state.GroupDetail = GroupDetail;
      state.Validation = GroupDetailValidation;
    },
    setGroupDropDownList(state, action) {
      state.DropDownList = action.payload;
    },
    setGroupID(state, action) {
      state.GroupID = action.payload;
    },
    setGroupList(state, action) {
      state.GroupList = action.payload;
    },
    setGroupDetail(state, action) {
      state.GroupDetail = action.payload;
      state.Validation = GroupDetailValidation;
    },
    setGroupData(state, action) {
      const { type, name, value } = action.payload;
      if (type === "detail") {
        state.GroupDetail = { ...state.GroupDetail, [name]: value };
      } else if (type === "validation") {
        state.Validation = {
          ...state.Validation,
          [name]: { ...state.Validation[name], ...value },
        };
      }
    },
    setDriverList(state, action) {
      state.DriverList = action.payload;
    },
  },
});

export const {
  clear,
  clearData,
  setGroupDropDownList,
  setGroupData,
  setGroupDetail,
  setGroupID,
  setGroupList,
  setDriverList,
} = group.actions;

export default group.reducer;
