import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
const Index = createSlice({
  name: "Layout2",
  initialState: initialState,
  reducers: {
    setMobileView(state, action) {
      state.IsMobileView = action.payload;
      if (state.IsMobileView === true) {
        state.IsCollapsed = false;
        state.SidebarWidth = "320px";
      }
    },
    setMinimalView(state, action) {
      state.IsMinimalView = action.payload;
    },
    setCollapsed(state, action) {
      state.IsCollapsed = action.payload;
      state.IsGroupInfoOpened = false;
      state.IsProfileOpened = false;
    },

    setPartialCollapsed(state, action) {
      state.IsPartialCollapsed = action.payload;
      state.IsGroupInfoOpened = false;
      state.IsProfileOpened = false;
    },
    setSidebarWidth(state, action) {
      state.SidebarWidth = action.payload;
    },
    setProfileOpen(state, action) {
      state.IsProfileOpened = action.payload;
      state.IsGroupInfoOpened = false;
    },
    setGroupInfoOpen(state, action) {
      state.IsGroupInfoOpened = action.payload;
      state.IsProfileOpened = false;
    },
    clearMenuState(state) {
      state = initialState;
    },
  },
});

export const {
  setProfileOpen,
  setGroupInfoOpen,
  setMobileView,
  setMinimalView,
  setCollapsed,
  setPartialCollapsed,
  setSidebarWidth,
  clearMenuState,
} = Index.actions;
export default Index.reducer;
