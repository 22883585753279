import React from "react";
import { createBrowserRouter } from "react-router-dom";
import PrivateRoute from "../../api/PrivateRoute";

const PageNotFound = React.lazy(() => import("../../pages/page-not-found"));
const MobileLayout = React.lazy(() => import("../../layouts/mobile"));

const MainLayout = React.lazy(() => import("../../layouts/layout-2"));
const DriverMobileLayout = React.lazy(() =>
  import("../../layouts/driver-mobile-layout")
);

const PassengerMobileLayout = React.lazy(() =>
  import("../../layouts/passenger-mobile-layout")
);

const PublicRegistrationPage = React.lazy(() =>
  import("../../pages/public/registration")
);

const Login = React.lazy(() => import("../../pages/login/login-1"));
const Home = React.lazy(() => import("../../pages/home"));
const HistoryPage = React.lazy(() => import("../../pages/history"));

const VehicleGroupListPage = React.lazy(() =>
  import("../../pages/vehicle-model/group")
);
const VehicleGroupItemPage = React.lazy(() =>
  import("../../pages/vehicle-model/group/item")
);

const GroupListPage = React.lazy(() => import("../../pages/group/list"));
const GroupItemPage = React.lazy(() => import("../../pages/group/item"));

const VehicleDetailListPage = React.lazy(() =>
  import("../../pages/vehicle-model/detail")
);
const VehicleDetailItemPage = React.lazy(() =>
  import("../../pages/vehicle-model/detail/item")
);

const VehicleCategoryListPage = React.lazy(() =>
  import("../../pages/masters/vehicle-category")
);
const VehicleCategoryItemPage = React.lazy(() =>
  import("../../pages/masters/vehicle-category/item")
);

const LeaderGroupListPage = React.lazy(() =>
  import("../../pages/leader-group-info/list")
);
const LeaderGroupItemPage = React.lazy(() =>
  import("../../pages/leader-group-info/item")
);

const DriverProfileListPage = React.lazy(() =>
  import("../../pages/registration/driver-profile")
);
const DriverProfileItemPage = React.lazy(() =>
  import("../../pages/registration/driver-profile/item")
);

const VehicleProfileListPage = React.lazy(() =>
  import("../../pages/registration/vehicle-profile")
);
const VehicleProfileItemPage = React.lazy(() =>
  import("../../pages/registration/vehicle-profile/item")
);

const SubscriptionListPage = React.lazy(() =>
  import("../../pages/masters/subscription")
);

const SubscriptionItemPage = React.lazy(() =>
  import("../../pages/masters/subscription/item")
);

const BookingListPage = React.lazy(() =>
  import("../../pages/booking/booking-list")
);
const BookingDetailPage = React.lazy(() =>
  import("../../pages/booking/booking-detail")
);

const BookingHistoryPage = React.lazy(() =>
  import("../../pages/booking/booking-history")
);

const DriverHomePage = React.lazy(() =>
  import("../../pages/driver-mobile/home")
);

const DriverMobileBookingDetailPageOld = React.lazy(() =>
  import("../../pages/booking/booking-detail/mobile-view")
);

const DriverMobileBookingDetailPage = React.lazy(() =>
  import("../../pages/booking/booking-detail/mobile-view-new")
);
const DriverMobileRecentListPage = React.lazy(() =>
  import("../../pages/booking/booking-list/mobile-view/recent-booking")
);

const DriverMobileWallet = React.lazy(() =>
  import("../../pages/driver-mobile/wallet")
);

const CompletedJobReport = React.lazy(() =>
  import("../../pages/reports/completed-job")
);

const SummaryJobsReport = React.lazy(() =>
  import("../../pages/reports/summary-jobs")
);

const Service1Page = React.lazy(() =>
  import("../../pages/control-panel/service-1")
);

const Index = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/public",
    element: <MobileLayout />,
    children: [
      {
        path: "registration/:MobileNo/:RegID",
        element: (
          <PrivateRoute>
            <PublicRegistrationPage />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <MainLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "history",
        element: <HistoryPage />,
      },
      {
        path: "vehicle",
        children: [
          {
            path: "group",
            element: <VehicleGroupListPage />,
            children: [
              {
                path: "detail",
                element: <VehicleGroupItemPage />,
              },
            ],
          },
          {
            path: "detail",
            element: <VehicleDetailListPage />,
            children: [{ path: "detail", element: <VehicleDetailItemPage /> }],
          },
          {
            path: "category",
            element: <VehicleCategoryListPage />,
            children: [
              {
                path: "item",
                element: <VehicleCategoryItemPage />,
              },
            ],
          },
        ],
      },
      {
        path: "group",
        element: <GroupListPage />,
        children: [{ path: "item", element: <GroupItemPage /> }],
      },
      {
        path: "leader/group",
        element: <LeaderGroupListPage />,
        children: [{ path: "item", element: <LeaderGroupItemPage /> }],
      },
      {
        path: "registration",
        children: [
          {
            path: "driver",
            children: [
              { index: true, path: "", element: <DriverProfileListPage /> },
              { path: ":DriverID", element: <DriverProfileItemPage /> },
            ],
          },
          {
            path: "vehicle",
            children: [
              {
                path: "list",
                element: <VehicleProfileListPage />,
                children: [
                  { path: ":DriverID", element: <VehicleProfileListPage /> },
                ],
              },
              { path: "", element: <VehicleProfileItemPage /> },
              { path: ":VehicleID", element: <VehicleProfileItemPage /> },
            ],
          },
        ],
      },
      {
        path: "master/sm",
        element: <SubscriptionListPage />,
        children: [{ path: "item", element: <SubscriptionItemPage /> }],
      },
      {
        path: "booking",
        children: [
          {
            path: "leader",
            element: <BookingListPage />,
          },

          {
            path: "history",
            element: <BookingHistoryPage />,
          },
          {
            path: "leader/detail",
            element: <BookingDetailPage IsLeaderBooking={true} />,
            children: [
              {
                path: ":BookingID",
                element: <BookingDetailPage IsLeaderBooking={true} />,
              },
            ],
          },
          { path: "driver", element: <BookingListPage /> },
          {
            path: "driver/detail",
            element: <BookingDetailPage />,
            children: [
              {
                path: ":BookingID",
                element: <BookingDetailPage />,
              },
            ],
          },
          {
            path: "quick",
            element: <BookingListPage />,
          },
          {
            path: "quick/detail",
            element: <BookingDetailPage />,
            children: [
              {
                path: ":BookingID",
                element: <BookingDetailPage />,
              },
            ],
          },
        ],
      },
      {
        path: "rpt",
        children: [
          {
            path: "completed-job",
            element: <CompletedJobReport />,
          },
          {
            path: "summary-job",
            element: <SummaryJobsReport />,
          },
        ],
      },
      {
        path: "cpanel",
        children: [
          {
            path: "service-1",
            element: <Service1Page />,
          },
        ],
      },
      { path: "*", element: <PageNotFound /> },
    ],
  },
  {
    path: "mobile",
    element: (
      <PrivateRoute>
        <DriverMobileLayout />
      </PrivateRoute>
    ),
    children: [
      // { index: true, path: "", element: <DriverHomePage /> },
      { index: true, path: "", element: <DriverHomePage /> },
      {
        path: "detail",
        element: <BookingDetailPage />,
        children: [
          {
            path: ":BookingID",
            element: <BookingDetailPage />,
          },
        ],
      },
      { path: "recent", element: <DriverMobileRecentListPage /> },
      { path: "wallet", element: <DriverMobileWallet /> },
      {
        path: "booking",
        element: (
          <BookingListPage ActivateMobileView={true} IsLeaderBooking={false} />
        ),
      },
      {
        path: "booking/:BookingID",
        element: <DriverMobileBookingDetailPage />,
      },
      {
        path: "booking/old/:BookingID",
        element: <DriverMobileBookingDetailPageOld />,
      },
    ],
  },
  {
    path: "passenger",
    element: <PassengerMobileLayout />,
    children: [
      { index: true, path: "", element: <DriverHomePage /> },

      {
        path: "booking/:BookingID",
        element: <DriverMobileBookingDetailPage />,
      },
    ],
  },
]);

export default Index;
