import axios from "axios";

const GetToken = () => {
  const tmp = localStorage.getItem("Profile");
  let token = "";
  if (tmp !== "") {
    const profile = JSON.parse(tmp);
    token = profile?.token || "";
  }
  return token;
};

const refreshAccessToken = async () => {
  try {
    let token = "";
    const res = await instance.post("/auth/generatetoken");
    if (res.status === 200) {
      if (res.data.type === "success") {
        token = res.data.token;
      }
    }
    return token;
  } catch (error) {
    throw error;
  }
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "X-Custom-Header": "eevom",
    "X-Access-Token": GetToken(),
    "ngrok-skip-browser-warning": true,
    // "Access-Control-Allow-Origin": "*",
    // "Content-Type": "application/json",
  },
  xsrfCookieName: "XSRF-EEVOM",
  xsrfHeaderName: "X-XSRF-EEVOM",
  transformResponse: [
    function (data) {
      let res = null;
      if (data) res = JSON.parse(data);
      return res;
      // const res = JSON.parse(data);
      // return res;
    },
  ],
});

instance.interceptors.request.use(
  async (config) => {
    config.headers = { "X-Access-Token": GetToken() };
    // console.log(config.method);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const errorObject = error;
    if (error.message === "Network Error" || error.code === "ERR_NETWORK")
      throw Error(
        "Please check your internet connection.Unable to connect Backend service.Please try again later."
      );
    if (error.config.url !== "/auth/generatetoken" && error.response) {
      if (error.response.status === 500) {
        // console.log(error.response.data.message);
        if (error.response.data.message === "jwt expired") {
          const originalRequest = error.config;
          // console.log(originalRequest);
          const access_token = await refreshAccessToken();
          if (access_token !== "") {
            if (localStorage.getItem("Profile")) {
              let profile = JSON.parse(localStorage.getItem("Profile"));
              profile = { ...profile, token: access_token };
              localStorage.setItem("Profile", JSON.stringify(profile));

              originalRequest.headers["X-Access-Token"] = access_token;
              originalRequest._retry = true;
              return instance(originalRequest);
            }
          } else {
            localStorage.clear();
          }
          // console.log(access_token);
        }
      }
    } else if (error.response.status === 401) {
      localStorage.clear();
    }
    // else if (
    //   error.response.status === 500 &&
    //   error.response.data.message === "jwt expired" &&
    //   !originalRequest._retry
    // ) {
    //   if (
    //     access_token !== undefined &&
    //     access_token !== null &&
    //     access_token !== ""
    //   ) {
    //     instance.defaults.headers.common["X-Access-Token"] = access_token;
    //     originalRequest._retry = true;
    //     return instance(originalRequest);
    //   } else {
    //     localStorage.clear();
    //   }
    // }
    return Promise.reject(errorObject);
  }
);

export default instance;
