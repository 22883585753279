export const VehicleProfile = {
  RegID: null,
  DriverID: null,
  DriverName: "",
  VehicleID: null,
  VehiclePhoto: null,
  Model: "",
  Category: "",
  MakeYear: "",
  Color: "",
  PlatNo: "",
  SeatCapacity: "",
  GrantFileName: null,
  InsuranceNo: "",
  InsuranceExpiry: "",
  InsuranceFileName: null,
  RoadTaxNo: "",
  RoadTaxExpiry: "",
  RoadTaxFileName: null,
  EVPNo: "",
  EVPExpiry: "",
  EVPPhoto: null,
  PuspakomCertPhoto: null,
  Status: "",
};
export const Validation = {
  RegID: { label: "Telegram Reference", require: true, valid: true },
  DriverID: { label: "Driver ID", require: true, valid: true },
  DriverName: { label: "Driver Name", require: true, valid: true },
  VehicleID: { label: "Vehicle ID", require: true, valid: true },
  VehiclePhoto: { label: "Vehicle Photo", require: true, valid: true },
  Model: { label: "Vehicle Model", require: true, valid: true },
  Category: { label: "Vehicle Category", require: false, valid: true },
  MakeYear: { label: "Make Year", require: true, valid: true },
  Color: { label: "Vehicle Color", require: true, valid: true },
  PlatNo: {
    label: "Plat No",
    require: true,
    valid: true,
  },
  SeatCapacity: { label: "Luggage Capacity", require: false, valid: true },
  GrantFileName: { label: "Vehicle Grant", require: true, valid: true },
  InsuranceNo: {
    label: "Insurance No",
    require: false,
    valid: true,
  },
  InsuranceExpiry: {
    label: "Insurance Expiry",
    require: true,
    valid: true,
  },
  InsuranceFileName: {
    label: "Insurance Cover Note",
    require: true,
    valid: true,
  },
  RoadTaxNo: { label: "Road Tax No", require: false, valid: true },
  RoadTaxExpiry: { label: "Road Tax Expiry", require: true, valid: true },
  RoadTaxFileName: {
    label: "Road Tax Photo",
    require: true,
    valid: true,
  },
  EVPNo: { label: "EVP No", require: false, valid: true },
  EVPExpiry: { label: "EVP Expiry", require: false, valid: true },
  EVPFileName: {
    label: "EVP Photo",
    require: false,
    valid: true,
  },
  PuspakomCertFileName: {
    label: "Puspakom Cert Photo",
    require: false,
    valid: true,
  },
  Status: {
    label: "Status",
    require: true,
    valid: true,
  },
};
const Index = {
  Updated: false,
  List: [],
  Detail: VehicleProfile,
  Validation: Validation,
};
export default Index;
