import { createSlice } from "@reduxjs/toolkit";
import initialState, {
  VehicleDetail,
  VehicleDetailValidationn,
  VehicleGroupDetail,
  VehicleGroupValidation,
} from "./initialState";

const VM = createSlice({
  name: "VM",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      const { type, name, value } = action.payload;
      if (type === "group") {
        state.Group.Detail = { ...state.Group.Detail, [name]: value };
      } else if (type === "detail") {
        state.Detail.Detail = { ...state.Detail.Detail, [name]: value };
      } else if (type === "group validation") {
        state.Group.Validation = {
          ...state.Group.Validation,
          [name]: { ...state.Group.Validation[name], ...value },
        };
      } else if (type === "detail validation") {
        state.Detail.Validation = {
          ...state.Detail.Validation,
          [name]: { ...state.Detail.Validation[name], ...value },
        };
      }
    },
    setClearData(state, action) {
      const { type } = action.payload;
      if (type === "group") {
        state.Group.Detail = VehicleGroupDetail;
        state.Group.Validation = VehicleGroupValidation;
      } else if (type === "detail") {
        state.Detail.Detail = VehicleDetail;
        state.Detail.Validation = VehicleDetailValidationn;
      }
    },
    setModelData(state, action) {
      const { type, model } = action.payload;
      if (type === "group") {
        state.Group.Detail = model;
        state.Group.Validation = VehicleGroupValidation;
      } else if (type === "detail") {
        state.Detail.Detail = model;
        state.Detail.Validation = VehicleDetailValidationn;
      }
    },
    clearModelGroupState(state) {
      state.Group.Detail = VehicleGroupDetail;
      state.Group.Validation = VehicleGroupValidation;
      state.Group.List = [];
    },
    clearModelDetailState(state) {
      state.Detail.Detail = VehicleDetail;
      state.Detail.Validation = VehicleDetailValidationn;
    },
    setModelGroupList(state, action) {
      state.Group.List = action.payload;
    },
    setList(state, action) {
      const { type, list } = action.payload;
      if (type === "group") state.Group.List = list;
      else if (type === "detail") state.Detail.List = list;
    },
  },
});

export const {
  setData,
  setClearData,
  setModelData,
  setModelGroupList,
  setList,
} = VM.actions;
export default VM.reducer;
