import { bookingMobileViewData } from "./initialState";

import { createSlice } from "@reduxjs/toolkit";
const BMV = createSlice({
  name: "BMV",
  initialState: bookingMobileViewData,
  reducers: {
    setLoadData(state, action) {
      const {
        MobileBookingView,
        CurrentLocation,
        Origin,
        Destination,
        LiveLocation,
      } = action.payload;
      if (MobileBookingView !== undefined && MobileBookingView !== null) {
        state.MobileBookingView = MobileBookingView;
      }
      if (CurrentLocation !== undefined && CurrentLocation !== null) {
        state.CurrentLocation = CurrentLocation;
      }
      if (LiveLocation !== null && LiveLocation !== undefined)
        state.LiveLocation = LiveLocation;
      if (Origin !== undefined && Origin !== null) state.Origin = Origin;
      if (Destination !== undefined && Destination !== null)
        state.Destination = Destination;
    },
    setShowCancelModal(state, action) {
      const data = action.payload;
      state.ShowCancelModal = data.ShowCancelModal ?? false;
      state.CancelData = {
        Request2Cancel: data.Request2Cancel ?? true,
        Allow2Cancel: data.Allow2Cancel ?? false,
        Approval: data.Approval ?? false,
      };
    },
    setMBVClear(state) {
      state.CancelData = null;
      state.CurrentLocation = null;
      state.Destination = null;
      state.MobileBookingView = null;
      state.Origin = null;
      state.ShowCancelModal = false;
      state.ShowFareModel = false;
      state.YourLocation = null;
    },
  },
});

export const { setLoadData, setShowCancelModal, setMBVClear } = BMV.actions;

export default BMV.reducer;
