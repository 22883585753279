import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { injectGlobal } from "@emotion/css";
import { SnackbarProvider } from "notistack";

import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// import * as serviceWorker from "./serviceWorker";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store from "./store";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

injectGlobal({
  //   html: {
  //     height: "100%",
  //     width: "100%",
  //   },
  body: {
    // height: "100%",
    // width: "100%",
    background: "#E3E8EE",
    margin: "0px",
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider
        autoHideDuration={5000}
        maxSnack={5}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ zIndex: 9998 }}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>
);

// serviceWorker.register();
serviceWorkerRegistration.register();
