import { createSlice } from "@reduxjs/toolkit";
import initialState, {
  BookingDetail,
  FilterData,
  Validation,
} from "./initialState";

const LB = createSlice({
  name: "LeaderBooking",
  initialState: initialState,
  reducers: {
    setUpdate(state, action) {
      const { Updated, CurrentStatus } = action.payload;
      if (Updated !== undefined && Updated !== null) state.Update = Updated;
      if (CurrentStatus !== undefined && CurrentStatus !== null)
        state.CurrentStatus = CurrentStatus;
    },
    setData(state, action) {
      const { type, name, value } = action.payload;
      if (type === "item") {
        state.Detail = { ...state.Detail, [name]: value };
      } else if (type === "detail") {
        state.Detail = value;
      } else if (type === "validation-detail") {
        state.Validation = { ...value };
      } else if (type === "validation") {
        state.Validation = {
          ...state.Validation,
          [name]: { ...state.Validation[name], ...value },
        };
      } else if (type === "filter") {
        state.Filter = { ...state.Filter, [name]: value };
      }
    },
    setList(state, action) {
      state.List = action.payload;
    },
    clear(state) {
      state.Detail = null;
      state.List = null;
      state.Update = false;
      state.Validation = null;
      state.Filter = FilterData;
    },
    clearData(state) {
      state.Detail = BookingDetail;
      state.Validation = Validation;
      state.Update = false;
      state.ShowCancelModal = false;
    },
    setShowCancelModal(state, action) {
      const data = action.payload;
      state.ShowCancelModal = data.ShowCancelModal ?? false;
      state.CancelData = {
        Request2Cancel: data.Request2Cancel ?? true,
        Allow2Cancel: data.Allow2Cancel ?? false,
        Approval: data.Approval ?? false,
      };
    },

    setShowFareModal(state, action) {
      state.ShowFareModel = action.payload;
    },
    setBookingID(state, action) {
      state.BookingID = action.payload;
    },
  },
});

export const {
  setData,
  setUpdate,
  setList,
  clear,
  clearData,
  setShowCancelModal,
  setShowFareModal,
  setBookingID,
} = LB.actions;
export default LB.reducer;
