const Index = {
  WalletLedgerList: [],
  WalletControlList: [],
  LeaderList: [],
  showLeaderList: false,
  showBookingList: false,
  WalletBalance: 0,
  showWalletAdjustment: false,
  showWalletHistory: false,
};

export default Index;
