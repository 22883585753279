export const LeaderGroupItem = {
  lgm_id: null,
  lgm_desc: "",
  lgm_code: "",
  MaxAllowedLeader: 0,
  MaxAllowedMember: 0,
  IsActive: true,
  IsVoid: false,
};
export const LeaderGroupItemValidation = {
  lgm_id: null,
  lgm_desc: { label: "Group Name", require: true, valid: true },
  lgm_code: { label: "Group Code", require: false, valid: true },
  MaxAllowedLeader: {
    label: "Max Allowed Leader",
    require: true,
    valid: true,
  },
  MaxAllowedMember: {
    label: "Max Allowed Member",
    require: true,
    valid: true,
  },
  IsActive: { label: "Status", require: true, valid: true },
  IsVoid: { label: "Void", require: false, valid: true },
};

const Index = {
  List: [],
  Detail: LeaderGroupItem,
  Validation: LeaderGroupItemValidation,
};

export default Index;
