export const VehicleGroupDetail = {
  mg_id: null,
  mg_desc: "",
  mg_code: "",
  mg_order: 0,
  IsActive: true,
  IsVoid: false,
};
export const VehicleGroupValidation = {
  mg_id: null,
  mg_desc: { label: "Description", require: true, valid: true },
  mg_code: { label: "Code", require: false, valid: true },
  mg_order: {
    label: "Order By",
    require: false,
    AcceptOnlyNumbers: true,
    AllowNegative: false,
    valid: true,
  },
  IsActive: { label: "Status", require: true, valid: true },
  IsVoid: { label: "Void", require: false, valid: true },
};
export const VehicleDetail = {
  md_id: null,
  mg_id: null,
  md_desc: "",
  md_code: "",
  mc_id: null,
  SeatCapacity: "0",
  md_order: 0,
  IsActive: true,
  IsVoid: false,
};
export const VehicleDetailValidationn = {
  md_id: null,
  mg_id: { label: "Vehicle Group", require: true, valid: true },
  md_desc: { label: "Description", require: true, valid: true },
  md_code: { label: "Code", require: false, valid: true },
  mc_id: { label: "Category", require: true, valid: true },
  SeatCapacity: { label: "Seating Capacity", require: true, valid: true },
  md_order: {
    label: "Order By",
    require: false,
    AcceptOnlyNumbers: true,
    AllowNegative: false,
    valid: true,
  },
  IsActive: { label: "Status", require: true, valid: true },
  IsVoid: { label: "Void", require: false, valid: true },
};

const Index = {
  Group: {
    List: [],
    Detail: VehicleGroupDetail,
    Validation: VehicleGroupValidation,
  },
  Detail: {
    List: [],
    Detail: VehicleDetail,
    Validation: VehicleDetailValidationn,
  },
};
export default Index;
