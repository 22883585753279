import React from "react";
import { RouterProvider } from "react-router-dom";

import Spinner from "../components/spinners/eevom";
import RouterData from "./router-data";

const Index = ({ children }) => {
  return (
    <>
      {children}
      <React.Suspense fallback={<Spinner />}>
        <RouterProvider router={RouterData} />
      </React.Suspense>
    </>
  );
};
export default Index;
