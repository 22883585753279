import { createSlice } from "@reduxjs/toolkit";

import initialState from "./initialState";

const AuthSlice = createSlice({
  name: "Auth",
  initialState: initialState,
  reducers: {
    setLoad(state, action) {
      if (isNaN(action.payload)) state.isLoading = 0;
      if (parseInt(action.payload) > 0)
        if (state.isLoading < 0) state.isLoading = 0;
      state.isLoading = state.isLoading + parseInt(action.payload);
    },
    setShowModal(state, action) {
      state.showModal = action.payload;
    },
    setNotification(state, action) {
      const {
        payload: { type, title, message },
      } = action;
      let data = {};
      if (type !== undefined) data = { ...data, type };
      if (title !== undefined) data = { ...data, title };
      if (message !== undefined) data = { ...data, message };
      state.notification = data;
    },

    signIn(state, action) {
      let data = { ...state.UserLoginInfo };
      const {
        payload: {
          RegID,
          RefID,
          Counter,
          CountryCode,
          MobileNo,
          isAuthenticated,
          FullName,
          token,
          DriverID,
          DriverPhoto,
          activeLGM,
          LgmList,
          IsApproved,
        },
      } = action;

      if (activeLGM !== undefined) {
        data = { ...data, activeLGM: activeLGM };
      } else data = { ...data, activeLGM: null };
      if (LgmList !== undefined) {
        data = { ...data, LgmList };
      }
      if (RegID !== undefined) {
        data = { ...data, RegID };
      }
      if (CountryCode !== undefined) {
        data = { ...data, CountryCode };
      }
      if (MobileNo !== undefined) {
        data = { ...data, MobileNo };
      }
      if (RefID !== undefined) {
        data = { ...data, RefID };
      }
      if (Counter !== undefined) {
        data = { ...data, Counter };
      }
      if (FullName !== undefined) {
        data = { ...data, FullName };
      }
      if (IsApproved !== undefined) {
        data = { ...data, IsApproved };
      }
      if (token !== undefined) {
        data = { ...data, token };
      }
      if (DriverID !== undefined) {
        data = { ...data, DriverID };
      }
      if (DriverPhoto !== undefined && DriverPhoto !== null) {
        data = { ...data, DriverPhoto };
      }
      state.isAuthenticated = isAuthenticated || false;
      state.UserLoginInfo = data;
    },
    clearAuthState(state) {
      localStorage.clear();
      state.isAuthenticated = false;
      state.notification = null;
      state.UserLoginInfo = {};
      state.isLoading = false;
    },
    setSaveSuccess(state, action) {
      state.saveSuccess = action.payload;
    },
    setLgmList(state, action) {
      state.UserLoginInfo.LgmList = action.payload;
    },
    setActiveLGM(state, action) {
      state.UserLoginInfo.activeLGM = action.payload;
      let profile = JSON.parse(localStorage.getItem("Profile"));
      profile = { ...profile, activeLGM: action.payload };
      localStorage.setItem("Profile", JSON.stringify(profile));
    },
    setActivateReg(state, action) {
      state.ActivateRegistration = action.payload;
    },
  },
});

export const {
  setLoad,
  setLgmList,
  setActiveLGM,
  setNotification,
  signIn,
  clearAuthState,
  setShowModal,
  setSaveSuccess,
  setActivateReg,
} = AuthSlice.actions;

export default AuthSlice.reducer;
