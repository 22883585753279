import axios from "../../api/axios";
import {
  setLoad,
  setNotification,
  signIn,
  clearAuthState,
  setActivateReg,
} from "./";
import { clearMenuState } from "../menu";
import { clearMenuState as clearLayoutMenu } from "../menu/layout-2";
import { BlobRul } from "./image";
import { getMenuList } from "../menu/menuThunk";
import moment from "moment";
import { setWalletBalance } from "../wallet";

const verifyMobile = (data) => {
  return async (dispatch) => {
    dispatch(setLoad(1));
    try {
      const response = await axios.post("/auth/VerifyMobile", data);
      if (response.statusText === "OK" || response.status === 200) {
        const {
          data: { type, message },
        } = response;
        if (type === "success") {
          // const { CountryCode, MobileNo } = data;
          // const { RegID, RefID, Counter } = response.data.data;
          const { CountryCode } = data;
          const { RegID, RefID, Counter, MobileNo } = response.data.data;
          dispatch(signIn({ CountryCode, MobileNo, RegID, RefID, Counter }));
        } else if (type === "error") {
          if (message === "Your profile not yet registered in our system")
            dispatch(setActivateReg(true));
          else throw new Error(message);
        }
      }
    } catch (error) {
      dispatch(setError(error));
    }
    dispatch(setLoad(-1));
  };
};

const verifyOTP = (data) => {
  return async (dispatch) => {
    dispatch(setLoad(1));
    try {
      const response = await axios.post("/auth/VerifyOTP", data);
      if (response.statusText === "OK" || response.status === 200) {
        const {
          data: { type, message },
        } = response;
        if (type === "success") {
          const {
            Name,
            token,
            status,
            IsApproved,
            CountryCode,
            MobileNo,
            RegID,
            DriverID,
            LGD,
            Photo,
            onTripDetail,
          } = response.data.data;
          if (status === "Verified") {
            let activeLGM = {};
            let LgmList = [];
            if (LGD) {
              if (LGD.length > 0) {
                activeLGM = LGD[0];
                LgmList = LGD;
              }
            }
            dispatch(
              signIn({
                isAuthenticated: true,
                IsApproved: IsApproved,
                FullName: Name,
                token: token,
                RegID: RegID,
                DriverID: DriverID,
                DriverPhoto: Photo || {
                  FileName: "profile.png",
                  BlobUrl: BlobRul,
                },
                activeLGM: activeLGM,
                LgmList: LgmList,
              })
            );
            localStorage.setItem("isAuthenticated", true);
            localStorage.setItem(
              "Profile",
              JSON.stringify({
                token,
                FullName: Name,
                CountryCode,
                MobileNo,
                RegID,
                DriverID,
                DriverPhoto: Photo || {
                  FileName: "profile.png",
                  BlobUrl: BlobRul,
                },
                activeLGM: activeLGM,
                LgmList: LgmList,
                IsApproved: IsApproved,
              })
            );
            if (
              (onTripDetail.type || "") === "success" &&
              (onTripDetail.data?.TripID || "") !== ""
            ) {
              const {
                TripID,
                StartedDate,
                LGM: { lgm_desc },
                Vehicle: {
                  EVPExpiry,
                  EVPNo,
                  PlatNo,
                  model: { md_desc },
                },
              } = onTripDetail.data;
              localStorage.setItem("TripOn", "true");
              localStorage.setItem("TripID", TripID);
              localStorage.setItem(
                "StartedDate",
                moment(StartedDate).format("DD-MMM-YYYY HH:mm")
              );
              localStorage.setItem("TripEVPNo", EVPNo);
              localStorage.setItem(
                "TripEVPExpiry",
                moment(EVPExpiry, "YYYY-MM-DD").format("DD/MM/YYYY")
              );
              localStorage.setItem("TripLGM", lgm_desc);
              localStorage.setItem("TripPlatNo", PlatNo);
              localStorage.setItem("TripVehicleModel", md_desc);
            } else {
              localStorage.removeItem("TripOn", "true");
              localStorage.removeItem("TripID");
              localStorage.removeItem("StartedDate");
              localStorage.removeItem("TripEVPNo");
              localStorage.removeItem("TripEVPExpiry");
              localStorage.removeItem("TripLGM");
              localStorage.removeItem("TripPlatNo");
              localStorage.removeItem("TripVehicleModel");
            }
            dispatch(
              setNotification({
                type: "success",
                title: "Success",
                message: "Login Success",
              })
            );
          } else {
            throw new Error("Authentication Failed");
          }
        } else if (type === "error") {
          throw new Error(message);
        }
      }
    } catch (error) {
      dispatch(setError(error));
    }
    dispatch(setLoad(-1));
  };
};

const autoSignIN = () => {
  return async (dispatch) => {
    try {
      const isAuthenticated = await localStorage.getItem("isAuthenticated");
      const Profile = JSON.parse(await localStorage.getItem("Profile"));
      if (isAuthenticated) {
        dispatch(signIn({ ...Profile, isAuthenticated: true }));
      }
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

const signOut = () => {
  return (dispatch) => {
    dispatch(clearAuthState());
    dispatch(clearMenuState());
    dispatch(clearLayoutMenu());
  };
};

const setError = (error) => {
  return async (dispatch) => {
    let errorMessage = "";
    if (error.response?.status === 401) {
      dispatch(signOut());
    } else if (error !== undefined) {
      if (error.response !== undefined) {
        errorMessage = error.response.data.message || "";
      } else if (error.message) errorMessage = error.message || "";
      else if (typeof error === "string") errorMessage = error; // Added new
      if (errorMessage === "logout") {
        localStorage.clear();
        dispatch(signOut());
      }
      if ((errorMessage || "").trim() !== "")
        dispatch(
          setNotification({
            type: "error",
            title: "Error",
            message: errorMessage,
          })
        );
    }
  };
};

const refreshProfileData = () => {
  return async (dispatch, getState) => {
    dispatch(setLoad(1));
    try {
      const {
        Auth: {
          UserLoginInfo: { activeLGM },
        },
      } = getState();
      const response = await axios.post("/driver/refresh").catch((e) => {
        throw e;
      });
      // debugger;
      if (response || response.status === 200) {
        const { type } = response.data;
        if (type === "success") {
          const {
            data: { DriverID, RegID, Name, LGD, Status, Photo },
          } = response.data;
          dispatch(setWalletBalance(response.data?.data?.WalletBalance ?? 0));
          if (Status > 3) {
            dispatch(signOut());
            throw Error("You're profile is de-activated");
          } else {
            let activeGroup = null;
            if (activeLGM) {
              const tmpActiveLGM = LGD.filter(
                (p) =>
                  p.lgm_id === activeLGM.lgm_id &&
                  p.RegID === activeLGM.RegID &&
                  p.lgm_desc === activeLGM.lgm_desc
              );
              if (tmpActiveLGM.length > 0) activeGroup = tmpActiveLGM[0];
              else activeGroup = null;
            } else if (LGD.length > 0) activeGroup = LGD[0];
            dispatch(
              signIn({
                isAuthenticated: true,
                FullName: Name,
                RegID: RegID,
                DriverID: DriverID,
                // DriverPhoto: Photo || {
                //   FileName: "profile.png",
                //   BlobUrl: BlobRul,
                // },
                DriverPhoto: Photo,
                activeLGM: activeGroup,
                LgmList: LGD,
              })
            );
          }
          dispatch(getMenuList());
        } else if (
          type === "error" &&
          response.data.message !==
            "Cannot read properties of null (reading 'RegID')"
        ) {
          if (
            response.data.message ===
            "You are not authorized to perform this operation"
          ) {
            dispatch(clearAuthState());
          } else throw new Error(response.data.message);
        }
      }
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(setLoad(-1));
    }
  };
};

export {
  verifyMobile,
  verifyOTP,
  autoSignIN,
  setError,
  signOut,
  refreshProfileData,
};
