import { createSlice } from "@reduxjs/toolkit";
import initialState, {
  LeaderGroupItem,
  LeaderGroupItemValidation,
} from "./initialState";

const VM = createSlice({
  name: "LGM",
  initialState: initialState,
  reducers: {
    setList(state, action) {
      const { list } = action.payload;
      state.List = list;
    },
    setData(state, action) {
      const { type, name, value, model } = action.payload;
      if (type === "item") {
        state.Detail = { ...state.Detail, [name]: value };
      } else if (type === "detail") {
        state.Detail = model;
      } else if (type === "validation") {
        state.Validation = {
          ...state.Validation,
          [name]: { ...state.Validation[name], ...value },
        };
      }
    },
    setClearData(state, action) {
      if (action.payload === "list") state.List = [];
      else if (action.payload === "detail") {
        state.Detail = LeaderGroupItem;
        state.Validation = LeaderGroupItemValidation;
      }
    },
  },
});

export const {
  setList,
  setClearData,
  setData,
  // setData,
  // setClearData,
  // setModelData,
  // setModelGroupList,
  // setList,
} = VM.actions;
export default VM.reducer;
