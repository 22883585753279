import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { refreshProfileData } from "../../store/auth/authThunk";

const Index = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.Auth);
  const isAuthenticated1 = localStorage.getItem("isAuthenticated");
  const location = useLocation();

  const refreshProfile = useCallback(() => {
    if (
      isAuthenticated &&
      !location.pathname.startsWith("/public/registration/")
    ) {
      dispatch(refreshProfileData());
    }
  }, [dispatch, isAuthenticated, location]);

  useEffect(() => {
    refreshProfile();
  }, [refreshProfile]);

  return isAuthenticated && isAuthenticated1 ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default Index;
