import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
const wallet = createSlice({
  name: "wallet",
  initialState: initialState,
  reducers: {
    setWalletLedgerList(state, action) {
      state.WalletLedgerList = action.payload;
    },
    setWalletControlList(state, action) {
      state.WalletControlList = action.payload;
    },
    setShowField(state, action) {
      if (action.payload.showLeaderList !== undefined)
        state.showLeaderList = action.payload.showLeaderList;
      if (action.payload.showBookingList !== undefined)
        state.showBookingList = action.payload.showBookingList;
    },
    setLeaderList(state, action) {
      state.LeaderList = action.payload;
    },
    setWalletBalance(state, action) {
      state.WalletBalance = action.payload;
    },
    setShowWalletAdjustment(state, action) {
      state.showWalletAdjustment = action.payload;
    },
    setShowWalletHistory(state, action) {
      state.showWalletHistory = action.payload;
    },
  },
});

export const {
  setWalletLedgerList,
  setWalletControlList,
  setShowField,
  setLeaderList,
  setWalletBalance,
  setShowWalletAdjustment,
  setShowWalletHistory,
} = wallet.actions;

export default wallet.reducer;
