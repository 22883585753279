import { createSlice } from "@reduxjs/toolkit";

const LGD = createSlice({
  name: "LGD",
  initialState: { List: [] },
  reducers: {
    setList(state, action) {
      state.List = action.payload;
    },
    setClearData(state) {
      state.List = [];
    },
    setData(state, action) {
      const { index, column, value } = action.payload;
      state.List[index]["IsLeader"] = false;
      state.List[index]["IsSubLeader"] = false;
      state.List[index]["IsMember"] = false;
      state.List[index][column] = value;
    },
  },
});

export const { setList, setClearData, setData } = LGD.actions;
export default LGD.reducer;
