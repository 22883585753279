import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import menuReducer from "./menu";
import pubRegReducer from "./public/registration";
import VM from "./vehicle-model";
import LGM from "./leader-group-info";
import LGD from "./leader-group-detail";
import Group from "./group";
import SM from "./subscription-master";
import Driver from "./driver";
import Vehicle from "./vehicle-profile";
import VC from "./vehicle-category";
import HL from "./history-log";
import Permission from "./module-permission";
import Layout2 from "./menu/layout-2";
import LeaderBooking from "./leader-booking";
import Wallet from "./wallet";
import MBV from "./booking-mobile-view";
import MVB from "./driver/mobile";
const store = configureStore({
  reducer: {
    Auth: authReducer,
    Menu: menuReducer,
    PubReg: pubRegReducer,
    VM: VM,
    LGM: LGM,
    SM: SM,
    Driver: Driver,
    Vehicle: Vehicle,
    VC: VC,
    HistoryLog: HL,
    Permission: Permission,
    LGD: LGD,
    Group: Group,
    Layout2: Layout2,
    LeaderBooking: LeaderBooking,
    Wallet: Wallet,
    MBV: MBV,
    MVB: MVB,
  },
});

export default store;
