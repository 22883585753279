export const bookingMobileViewData = {
  CurrentStatus: null,
  ShowFareModel: false,
  MobileBookingView: null,
  CurrentLocation: null,
  LiveLocation: null,
  YourLocation: false,
  Origin: null,
  Destination: null,
  ShowCancelModal: false,
  CancelData: null,
};
