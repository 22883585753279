export const GroupDetail = {
  lgd_id: null,
  lgm_id: null,
  RegID: null,
  Role: null,
  Status: true,
};
export const GroupDetailValidation = {
  lgd_id: null,
  lgm_id: { label: "Group Name", require: true, valid: true },
  RegID: { label: "Driver Name", require: true, valid: true },
  Role: { label: "Role", require: true, valid: true },
  Status: { label: "Status", require: true, valid: true },
};

const initialState = {
  DropDownList: [{ lgm_id: "", lgm_desc: "" }],
  GroupID: null,
  GroupList: [],
  DriverList: [],
  GroupDetail: GroupDetail,
  Validation: GroupDetailValidation,
};

export default initialState;
